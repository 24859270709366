import { SIGNUP_WEB_CHANNEL, SIGNUP_WEB_IDENTIFIER } from '../../constants/general';
import { Events } from '../../enums/events';
import {
  TrackersArgs,
  TrackHelpViewedArgs,
  TrackHelpArticleExpandedArgs,
  TrackCommonArgs,
  TrackStartDateSubmittedArgs,
  TrackScheduleSubmittedArgs,
  TrackPageViewedArgs,
  TrackCrossSellSelectedArgs,
  TrackPromocodeAddedArgs,
  TrackCallClickedArgs,
  TrackActionCompletedArgs,
} from '../../types/track';
import { lsSegmentService } from './segment';

export const populateCommonData = (payload: TrackersArgs) => {
  payload.customer_signup_version = SIGNUP_WEB_IDENTIFIER;
  payload.channel = SIGNUP_WEB_CHANNEL;
};

export const track = (event: Events, properties: TrackersArgs) => {
  populateCommonData(properties);
  lsSegmentService.track(event, properties);
};

export const trackHelpArticleExpanded = (properties: TrackHelpArticleExpandedArgs) =>
  track(Events.HelpArticleExpanded, properties);
export const trackHelpViewed = (properties: TrackHelpViewedArgs) => track(Events.HelpViewed, properties);
export const trackOnAddressChange = (properties: TrackCommonArgs) => track(Events.OnAddressChange, properties);
export const trackPageViewed = (properties: TrackPageViewedArgs) => track(Events.PageViewed, properties);
export const trackContactSubmitted = (properties: TrackCommonArgs) => track(Events.ContactSubmitted, properties);
export const trackCallClicked = (properties: TrackCallClickedArgs) => track(Events.CallClicked, properties);
export const trackStartDateSubmitted = (properties: TrackStartDateSubmittedArgs) =>
  track(Events.StartDateSubmitted, properties);
export const trackNotLookingForClicked = (properties: TrackCommonArgs) =>
  track(Events.NotLookingForClicked, properties);
export const trackPromocodeAdded = (properties: TrackPromocodeAddedArgs) => track(Events.PromocodeAdded, properties);
export const trackScheduleSubmitted = (properties: TrackScheduleSubmittedArgs) =>
  track(Events.ScheduleSubmitted, properties);
export const trackCrossSellSelected = (properties: TrackCrossSellSelectedArgs) =>
  track(Events.CrossSellSelected, properties);
export const trackActionCompleted = (properties: TrackActionCompletedArgs) => track(Events.ActionCompleted, properties);
