import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { updateContact } from '../../../store/modules/contact/slice';
import { store } from '../../../store';
import { selectContact } from '../../../store/modules/contact/slice';
import { EmailAlreadyTakenError } from '../../../constants/GraphQLErrors';
import { useSubmitProspectAsOrderMutation } from '../../../store/modules/prospect/api';
import _ from 'lodash';
import { lsI18NService } from '../../../service';
import { useTrackPayload } from '../../../hooks/useTrackPayload';
import { Events } from '../../../enums/events';
import { CustomerSignupAction, Price } from '../../../types/track';
import { trackActionCompleted } from '../../../service/segment/trackers';
import { selectProspect } from '../../../store/modules/prospect/slice';
import { centsToDollar } from '../../../helpers/currency';

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  termsCheckbox: '',
};

interface AccountAndPaymentProps {
  stripeFormRef: React.RefObject<HTMLFormElement>;
  checkboxRef: React.RefObject<HTMLFormElement>;
}

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const useAccountAndPayment = ({ stripeFormRef, checkboxRef }: AccountAndPaymentProps) => {
  const { hashId: prospectId } = useParams();
  const [submitProspectAsOrder, { data, isSuccess, isLoading, isUninitialized, error }] =
    useSubmitProspectAsOrderMutation();
  const { name, phone } = useSelector(selectContact);
  const prospect = useSelector(selectProspect);
  const selectedEstimates = prospect?.selectedServiceEstimates;
  const [isStripeFormValid, setIsStripeFormValid] = useState(false);
  const [shouldHighlightError, setShouldHighlightError] = useState(false);
  const [paymentToken, setPaymentToken] = useState<string | null>(null);
  const fullName = name?.split(' ');

  const { payloadBuilder: paymentAction, payloadBuilder: accountAction } = useTrackPayload(Events.ActionCompleted);

  const { control, formState, getValues, setError, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      firstName: fullName?.[0] || defaultValues.firstName,
      lastName: fullName?.[1] || defaultValues.lastName,
    },
  });
  const [isEmailAlreadyTaken, setIsEmailAlreadyTaken] = useState(false);

  // Handle validation errors
  useEffect(() => {
    const { message, error: emailError } = EmailAlreadyTakenError;

    if (error?.message?.includes(emailError)) {
      setIsEmailAlreadyTaken(true);
      setError('email', { type: 'error', message });
    }
  }, [error, setError]);

  useEffect(() => {
    watch((value, { name }) => {
      if (name !== 'termsCheckbox' || !value.termsCheckbox) {
        return;
      }

      setShouldHighlightError(false);
    });
  }, [watch, setShouldHighlightError]);

  const handleSubmit = () => {
    const { termsCheckbox, email } = getValues();
    if (!termsCheckbox) {
      setShouldHighlightError(true);
      checkboxRef.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    if (!EMAIL_REGEX.test(email)) {
      setError('email', { type: 'error', message: lsI18NService.t('accountAndPayment.emailInput.patternMessage') });
      return;
    }

    setShouldHighlightError(false);
    void stripeFormRef.current?.requestSubmit();
  };

  useEffect(() => {
    if (!paymentToken || !isUninitialized) return;

    const { firstName, lastName, email } = getValues();
    const contact = { name: `${firstName} ${lastName}`, phone, email };

    store.dispatch(updateContact({ email: getValues().email }));
    submitProspectAsOrder({ prospectId, contact, paymentToken });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentToken]);

  // Form validation of Contact + Stripe fields
  const isValid = useMemo(() => formState.isValid && isStripeFormValid, [formState.isValid, isStripeFormValid]);

  const getPricesAndFrequency = () => {
    if (!selectedEstimates) return { prices: [], selectedFrequency: undefined };

    const prices = selectedEstimates.map(
      (estimate) =>
        ({
          service: _.kebabCase(estimate.type),
          frequency: estimate.selectedEstimate?.cycle,
          price: centsToDollar(estimate.selectedEstimate?.amount, 2),
        }) as Price,
    );

    const selectedFrequency = selectedEstimates[0]?.selectedEstimate?.cycle;

    return { prices, selectedFrequency };
  };

  useEffect(() => {
    if (!isStripeFormValid || !selectedEstimates) return;

    const { prices, selectedFrequency } = getPricesAndFrequency();

    const payload = paymentAction({
      action: CustomerSignupAction.PAYMENT,
      selectedFrequency,
      prices,
    });

    payload && trackActionCompleted(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStripeFormValid, selectedEstimates]);

  const { firstName, lastName, email } = watch();

  useEffect(() => {
    const debouncedAccountAction = _.debounce(() => {
      if (selectedEstimates && firstName && lastName && EMAIL_REGEX.test(email)) {
        const { prices, selectedFrequency } = getPricesAndFrequency();

        const payload = accountAction({
          action: CustomerSignupAction.ACCOUNT,
          email,
          selectedFrequency,
          prices,
        });

        payload && trackActionCompleted(payload);
      }
    }, 500);

    debouncedAccountAction();

    return () => debouncedAccountAction.cancel();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName, email, selectedEstimates]);

  return {
    handleSubmit,
    setPaymentToken,
    setIsStripeFormValid,
    isEmailAlreadyTaken,
    shouldHighlightError,
    form: {
      isValid,
      control,
    },
    mutation: {
      data,
      isSuccess,
      isLoading,
    },
  };
};
